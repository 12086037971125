import { Button, Progress, Select, Table, TableColumnsType } from "antd";
import React, { ReactNode } from "react";
import s from "./Reports.module.scss";

interface DataType {
  key: React.Key;
  employee: ReactNode;
  script: ReactNode;
  compliance: ReactNode;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Сотрудник",
    dataIndex: "employee",
    key: "employee",
  },
  {
    title: "Соблюдения скрипта",
    dataIndex: "script",
    key: "script",
  },
  {
    title: "Соблюдение техник продаж",
    dataIndex: "compliance",
    key: "compliance",
  },
];

const Reports = () => {
  const TableData: DataType[] = [
    {
      key: 1,
      employee: <a href="/reports/script/454">Жакупова Айдана</a>,
      script: <Progress percent={50} showInfo={false} size={[292, 13]} />,
      compliance: <Progress percent={60} showInfo={false} size={[292, 13]} />,
    },
  ];

  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.filter}>
          <Select className={s.select} placeholder="Выберите период">
            <Select.Option value="monthly">Месячный</Select.Option>
          </Select>
          <Select className={s.select} placeholder="Выберите" defaultValue="subdivision">
            <Select.Option value="subdivision">Подразделение</Select.Option>
          </Select>
        </div>
        <Button>Экспорт </Button>
      </div>
      <div className={s.table}>
        <Table columns={columns} bordered dataSource={TableData} />
      </div>
    </div>
  );
};
export { Reports };
