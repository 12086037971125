import { Alert, Form } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import accountsApi from "../../api/Accounts/AccountsApi";
import Button from "../../components/ui/Button";
import { TextField } from "../../components/ui/TextField";

import clsx from "clsx";
import s from "./Login.module.scss";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");

  const onFinish = (values: any) => {
    accountsApi
      .login(values.username, values.password)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        setLoginError(`error: ${err}`);
      });
  };

  return (
    <Form
      name="normal_login"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      className={s.form}
    >
      <h2 className={s.title}>Авторизация</h2>
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите свое имя пользователя!",
          },
        ]}
        className={clsx(s.formGroup, s.marginBottom)}
      >
        <TextField placeholder="Введите e-mail" required fullWidth />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Пожалуйста, введите свой пароль!" }]}
        className={s.formGroup}
      >
        <TextField type="password" placeholder="Введите пароль" fullWidth required />
      </Form.Item>
      <Form.Item className={s.formGroup}>
        <a className={s.forgot} href="/forgot-password">
          Забыли пароль?
        </a>
      </Form.Item>
      <Form.Item className={clsx(s.formGroup, { [s.marginBottom]: loginError })}>
        <Button type="submit" className={s.submit} fullWidth>
          Войти в аккаунт
        </Button>
      </Form.Item>
      {loginError ? <Alert type="error" message="Электронная почта или пароль неверны" /> : null}
    </Form>
  );
};

export default LoginPage;
