import InformationSection from "./sections/InformationSection/InformationSection";
import VideoListSection from "./sections/VideoListSection/VideoListSection";
import VideoSection from "./sections/VideoSection/VideoSection";
import s from "./TrainingBase.module.scss";

const TrainingBase = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.main}>
        <VideoSection />
        <InformationSection
          title="Тема: Как правильно представиться клиенту"
          description={
            "В этом видео вы узнаете, как создать удачное первое впечатление при встрече с потенциальным клиентом.\n" +
            "Мы рассмотрим ключевые аспекты правильного представления себя, включая уверенность, четкость\n" +
            "и привлекательность. Вы научитесь формулировать краткое, но информативное представление о себе и своих услугах, привлекая внимание клиента и вызывая интерес к вашему предложению. Подготовьтесь узнать эффективные стратегии, которые помогут вам установить положительные и продуктивные отношения\n" +
            "с потенциальными клиентами и успешно заключить сделку."
          }
          isWatched
          pdf="fdfdf"
        />
      </div>
      <div className={s.sidebar}>
        <VideoListSection />
      </div>
    </div>
  );
};
export { TrainingBase };
