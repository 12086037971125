import { Col, DatePicker, Form, Row, Select, Table } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ApiService, GetDepartmentsWithEmployeesDepartment } from "../../../api";
import useFilterStore from "../../../store/filterStore";
import s from "./Analytics.module.scss";
import Employee from "./sections/MainSection/Employee/Employee";

const { MonthPicker } = DatePicker;

const Analytics = () => {
  const [form] = Form.useForm();
  const { filterValues, setFilterValues } = useFilterStore();
  const [departmentsWithManagers, setDepartmentsWithManagers] = useState<
    GetDepartmentsWithEmployeesDepartment[]
  >([]);
  const [selectedManagers, setSelectedManagers] = useState<any[]>([]);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);

  const loadDepartmentsWithManagers = async () => {
    const result = await ApiService.apiAccountsCompanyGetDepartmentsWithEmployeesList();
    setDepartmentsWithManagers(result);
  };

  const handleDepartmentChange = (value: any) => {
    const department = departmentsWithManagers.find((x: any) => x.id === value);

    const managers =
      department?.department_employees.map((y: any) => ({
        value: y.id,
        title: y.full_name,
      })) ?? [];
    setSelectedManagers(managers);

    setFilterValues({
      ...filterValues,
      department: value,
    });
  };

  useEffect(() => {
    (async () => {
      await loadDepartmentsWithManagers();
    })();
  }, []);

  const loadData = async () => {
    const statuses = (await ApiService.apiMainStatusesGetStatusesForRopRetrieve(
      filterValues?.date ?? dayjs().format("YYYY-MM-DD"),
      filterValues?.department,
      filterValues?.manager,
    )) as any;

    console.log(statuses);

    const statusesData = statuses.map((x: any) => ({
      less_2_day: x.less_2_day,
      name: x.name,
      more_2_day_less_4_day: x.more_2_day_less_4_day,
      more_4_day_less_month: x.more_4_day_less_month,
      total: x.total,
    }));

    setStatuses(statusesData);

    const tasks = (await ApiService.apiMainTasksGetTasksForRopRetrieve(
      filterValues?.date ?? dayjs().format("YYYY-MM-DD"),
      filterValues?.department,
      filterValues?.manager,
    )) as any;

    const tasksData = tasks.map((x: any) => ({
      overdue: x.overdue,
      name: x.name,
      without_task: x.without_task,
    }));

    setTasks(tasksData);
  };

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [filterValues, fetch]);

  return (
    <>
      <Form layout="horizontal" form={form}>
        <Row gutter={16} style={{ marginBottom: "2em" }}>
          <Col>
            <Form.Item
              label="Месяц"
              style={{ marginBottom: 0 }}
              name="decomposition_date"
              initialValue={dayjs()}
            >
              <MonthPicker
                format="YYYY-MM-DD"
                onChange={(value: any) =>
                  setFilterValues({
                    ...filterValues,
                    date: dayjs(value).format("YYYY-MM-DD"),
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Подразделение" style={{ marginBottom: 0 }}>
              <Select onChange={handleDepartmentChange} style={{ width: 200 }}>
                {departmentsWithManagers.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Менеджер" style={{ marginBottom: 0 }} name="manager_id">
              <Select
                style={{ width: 200 }}
                onChange={(value: any) =>
                  setFilterValues({
                    ...filterValues,
                    manager: value,
                  })
                }
              >
                {selectedManagers.map((x) => (
                  <Select.Option key={x.value} value={x.value}>
                    {x.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={s.root}>
        <div className={s.layout}>
          <div className={s.wrapper}>
            <div className={s.content}>
              <h4 className={s.title}>г. Астана, Мангелик Ел 144 (230)</h4>
              <div className={s.employees}>
                <Employee />
                <Employee />
              </div>
            </div>
          </div>
        </div>
        <div className={s.additionalInfo}>
          <div className={s.layout}>
            <div className={s.wrapper}>
              <h4 className={s.title}>СРМ</h4>
              <Table dataSource={tasks} bordered size="middle" pagination={false}>
                <Table.Column dataIndex="name" title="Этап воронки" />
                <Table.Column dataIndex="overdue" title="Просроченные" />
                <Table.Column dataIndex="without_task" title="Без задач" />
              </Table>
            </div>
          </div>
          <div className={s.layout}>
            <div className={s.wrapper}>
              <Table dataSource={statuses} bordered size="middle" pagination={false}>
                <Table.Column dataIndex="less_2_day" title="<2 д." />
                <Table.Column dataIndex="more_2_day_less_4_day" title="<3-4 д." />
                <Table.Column dataIndex="more_4_day_less_month" title="<мес." />
                <Table.Column dataIndex="total" title="Общ." />
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { Analytics };
