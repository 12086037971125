import { FC } from "react";
import ReactPlayer from "react-player/youtube";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { BlockList } from "../../../../../api";
import s from "./VideoList.module.scss";

type Props = {
  videoBlock: BlockList;
};
const VideoList: FC<Props> = ({ videoBlock }) => {
  return (
    <div>
      <h3>Блок: {videoBlock.name}</h3>
      <Swiper
        spaceBetween={25}
        slidesPerView="auto"
        pagination={{ clickable: false }}
        scrollbar={{ draggable: false }}
      >
        {videoBlock.block_trainings.map((training) => (
          <SwiperSlide style={{ width: "fit-content" }} key={training.id}>
            <div className={s.video}>
              <ReactPlayer
                width="100%"
                height="100%"
                url={training.video_url}
                config={{
                  playerVars: { showinfo: 0 },
                }}
              />
              <p className={s.title}>{training.title}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export { VideoList };
