import s from "./Feedback.module.scss";

export const Feedback = () => {
  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>
        Получите консультацию от наших специалистов
        <span>о сотрудничестве и регистрации на платформе Satoo</span>
      </h3>
      <form action="" className={s.form}>
        <input type="text" placeholder="Ваше имя" required />
        <input type="text" placeholder="Номер телефона" required />
        <input type="text" placeholder="Комментарий" required />
        <button type="submit">Оставить заявку</button>
      </form>
    </div>
  );
};
