import { createBrowserRouter } from "react-router-dom";
import { RoleEnum } from "../api";
import { LoginLayout } from "../layouts/LoginLayout";
import { LocalRole } from "../LocalRole";
import { LandingPage } from "../pages/LandingPage";
import ForgotPasswordPage from "../pages/Login/ForgotPasswordPage";
import LoginPage from "../pages/Login/LoginPage";
import ResetPasswordPage from "../pages/Login/ResetPasswordPage";
import { AppRoutesName } from "./AppRoutesName";
import { AdminRoutes } from "./roles/admin";
import { ManagerRoutes } from "./roles/manager";
import { OwnerRoutes } from "./roles/owner";

const role: string = LocalRole;

const userRoutes = () => {
  if (role === RoleEnum.ADMINISTRATOR) {
    return AdminRoutes;
  }
  if (role === RoleEnum.MANAGER) {
    return ManagerRoutes;
  }
  if (role === RoleEnum.OWNER || role === RoleEnum.ROP) {
    return OwnerRoutes;
  }
  if (!role) {
    return [
      {
        element: <LoginLayout />,
        children: [
          {
            path: "/forgot-password",
            element: <ForgotPasswordPage />,
          },
          {
            path: "/login",
            element: <LoginPage />,
          },
          {
            path: "/reset-password/:token",
            element: <ResetPasswordPage />,
          },
        ],
      },
    ];
  }
  return [];
};

export const AppRoutes = createBrowserRouter([
  {
    path: AppRoutesName.landingPage,
    element: <LandingPage />,
  },
  ...userRoutes(),
  {
    element: <LoginLayout />,
    children: [
      {
        path: "/forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPasswordPage />,
      },
    ],
  },
]);
