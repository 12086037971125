import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { FC, useState } from "react";
import { BlockList } from "../../../../../api";
import { AddVideoModal } from "./AddVideoModal";
import s from "./ControlPanel.module.scss";

type Props = {
  trainingBaseData: BlockList[];
  fetchTrainingBase: () => void;
};
const ControlPanel: FC<Props> = ({ trainingBaseData, fetchTrainingBase }) => {
  const [isOpenAddVideoModal, setIsOpenAddVideoModal] = useState<boolean>(false);
  return (
    <div className={s.search}>
      <Row justify="space-between" style={{ gap: "10px" }}>
        <Col>
          <div style={{ display: "flex", alignItems: " center" }}>
            <Form.Item noStyle name="name">
              <Input style={{ width: "40em" }} placeholder="Поиск.." />
            </Form.Item>
            <Button
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginLeft: "8px", padding: "10px" }}
            />
          </div>
        </Col>
        <Col>
          <Button onClick={() => setIsOpenAddVideoModal(true)}>Добавить видео</Button>
        </Col>
      </Row>
      <AddVideoModal
        isOpen={isOpenAddVideoModal}
        handleClose={() => setIsOpenAddVideoModal(false)}
        trainingBaseData={trainingBaseData}
        fetchTrainingBase={fetchTrainingBase}
      />
    </div>
  );
};
export { ControlPanel };
