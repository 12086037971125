const LogoYellow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="155"
      height="31"
      viewBox="0 0 155 31"
      fill="none"
    >
      <path
        d="M0 20.1129H7.43779C7.43779 20.1129 7.43779 25.0715 13.4982 25.0715C17.6303 25.0715 18.7322 23.1432 18.7322 21.4903C18.7322 15.4299 0.550947 20.1129 0.550947 9.09399C0.550947 4.13546 5.234 0.00335693 12.9473 0.00335693C23.9662 0.00335693 25.6191 6.33925 25.6191 9.09399H18.1813C18.1813 9.09399 18.1813 5.23736 12.9473 5.23736C9.91705 5.23736 8.26421 6.78001 8.26421 8.54304C8.26421 13.5016 26.4455 9.92041 26.4455 20.6639C26.4455 26.1734 21.7624 30.3055 13.4982 30.3055C2.20379 30.3055 0 23.4186 0 20.1129Z"
        fill="#282828"
      />
      <path
        d="M36.8877 9.64494H29.1744C29.6151 2.31734 35.5103 0.00335693 42.1217 0.00335693C49.0085 0.00335693 54.518 2.48262 54.518 9.92041V29.479H46.8047V23.9696H46.5292C46.5292 23.9696 44.7662 30.3055 37.4386 30.3055C32.2046 30.3055 28.348 27.8813 28.348 22.5922C28.348 17.248 32.7556 14.8238 37.4937 13.6118C42.2869 12.3997 46.8047 12.014 46.8047 8.81852C46.8047 6.55963 45.1519 5.23736 42.1217 5.23736C38.816 5.23736 36.9978 6.55963 36.8877 9.64494ZM46.8047 15.0442C45.5375 16.2012 42.9481 16.8073 40.7443 17.3582C38.265 17.9642 36.1714 19.2314 36.1714 21.7658C36.1714 23.9696 37.7141 25.3469 40.1933 25.3469C45.4273 25.3469 46.8047 19.2865 46.8047 15.0442Z"
        fill="#282828"
      />
      <path
        d="M55.8953 0.829777H81.7899V6.8902H72.4238V20.3884C72.4238 22.8677 73.2502 23.6941 75.7295 23.6941C77.3823 23.6941 78.2087 23.4186 78.2087 23.4186V29.2036C78.2087 29.2036 76.8313 29.7545 73.5257 29.7545C68.2917 29.7545 64.7105 28.3772 64.7105 21.7658V6.8902H55.8953V0.829777Z"
        fill="#282828"
      />
      <path
        d="M121.454 15.1544C121.454 20.3333 123.437 24.5205 128.341 24.5205C133.244 24.5205 135.227 20.3333 135.227 15.1544C135.227 9.97551 133.244 5.78831 128.341 5.78831C123.437 5.78831 121.454 9.97551 121.454 15.1544ZM113.741 15.1544C113.741 6.78001 118.919 0.00335693 128.341 0.00335693C137.762 0.00335693 142.941 6.78001 142.941 15.1544C142.941 23.5288 137.762 30.3055 128.341 30.3055C118.919 30.3055 113.741 23.5288 113.741 15.1544Z"
        fill="#282828"
      />
      <path
        d="M82.7178 15.1511C82.7178 6.77665 87.8967 0 97.3179 0C106.739 0 111.918 6.77665 111.918 15.1511C111.918 23.5255 106.739 30.3021 97.3179 30.3021C87.8967 30.3021 82.7178 23.5255 82.7178 15.1511Z"
        fill="#FFD966"
      />
      <path
        d="M95.171 30.1724V28.0989C94.8817 25.6034 94.1043 23.5814 92.893 22.2516C91.9347 21.1951 90.0725 20.2479 88.1742 19.8472C87.3968 19.665 86.7459 19.4646 86.7459 19.3918C86.7459 19.3189 87.0713 18.8453 87.451 18.3535C89.1143 16.2404 91.5551 15.949 94.7371 17.4973C95.2072 17.7341 95.6049 17.8799 95.6049 17.8252C95.6049 17.7706 95.388 17.133 95.1348 16.4226C94.7913 15.4936 94.6467 14.7467 94.6286 13.7631C94.6105 12.5609 94.6828 12.233 95.1891 11.2129C95.5145 10.5571 96.1292 9.68276 96.5631 9.24558L97.3586 8.46231L98.1361 9.33666C98.57 9.81027 99.1485 10.7211 99.4559 11.3586C99.944 12.4333 99.9802 12.6155 99.9079 14.091C99.8536 15.2204 99.6909 15.9854 99.3655 16.7869L98.8954 17.8981L100.324 17.2787C102.71 16.2404 104.735 16.2769 106.127 17.3516C106.742 17.8434 108.008 19.3735 107.881 19.4828C107.845 19.5011 107.085 19.7014 106.2 19.92C105.332 20.1386 104.229 20.4847 103.759 20.7215C102.728 21.2316 101.354 22.4885 100.776 23.4903C100.179 24.5286 99.6005 26.4959 99.4739 27.9896V30.1724C97.5989 30.4284 95.6876 30.2499 95.171 30.1724Z"
        fill="white"
      />
      <ellipse cx="150.582" cy="25.87" rx="4.41772" ry="4.43546" fill="#282828" />
    </svg>
  );
};
export default LogoYellow;
