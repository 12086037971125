/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
import {
  Button,
  Form,
  GetRef,
  Input,
  InputRef,
  message,
  Popconfirm,
  RefSelectProps,
  Row,
  Select,
  Table,
} from "antd";
import { OptionProps } from "antd/es/select";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ApiService, ManagerRequest } from "../../../../api";
import s from "../../css/Company/CompanyCreate.module.scss";
import "../../css/Company/CreateCompanySixthStep.css";
import { CreateCompanyProps } from "../../interfaces/Company";

type FormInstance<T> = GetRef<typeof Form<T>>;

export const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  full_name: string;
  email: string;
  department: string;
  manager_settings: string;
  crm_id: number;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  inputType: string;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key?: React.Key;
  full_name: string;
  email: string;
  department?: number | string;
  manager_settings?: number | string;
  crm_id: string;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export const CreateCompanySixthStep = ({
  company_id,
  company,
  setCurrent,
  current,
  stepBack,
}: CreateCompanyProps) => {
  // const { setFilterValues } = useFilterStore();
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [users, setUsers] = useState<OptionProps[]>([]);

  useEffect(() => {
    (async () => {
      const result = (await ApiService.apiMainGetCrmUserList(company_id!)).map((x) => ({
        value: x.crm_id,
        label: `${x.name} (${x.crm_id})`,
        children: null,
      }));
      setUsers(result);
    })();
  }, [company_id]);

  useEffect(() => {
    if (company?.managers) {
      const updatedDataSource = company.managers.map((manager) => ({
        key: manager.id, // Ensure you have a unique key for each row
        full_name: manager.full_name!,
        email: manager.email,
        department: manager.department_name,
        manager_settings: manager.manager_settings || "Выбрать..", // Adjust as needed
        crm_id: manager.crm_id!,
      }));
      setDataSource(updatedDataSource);
    }
  }, [company]);

  // const handleUserSearch = async (value: string) => {
  //   const result = (await ApiService.apiMainGetCrmUserList(company_id!, value)).map((x) => ({
  //     value: x.crm_id,
  //     label: x.name,
  //     children: null,
  //   }));
  //   // setUsers(result);
  //   setFilterValues(result);
  // };

  const managerSettingsMap = company?.company_manager_settings.reduce(
    (acc: Record<string, string>, item: any) => {
      acc[item.id] = item.name;
      return acc;
    },
    {},
  );

  const departmentsMap = company?.company_departments.reduce(
    (acc: Record<string, string>, item: any) => {
      acc[item.id] = item.name;
      return acc;
    },
    {},
  );

  const [count, setCount] = useState(1);

  const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title,
    editable,
    inputType,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const selectDepartmentRef = useRef<RefSelectProps>(null);
    const selectManagerSettingsRef = useRef<RefSelectProps>(null);
    const selectUserRef = useRef<RefSelectProps>(null);
    const form = useContext(EditableContext)!;

    // const handleUserSearch = async (value: string) => {
    //   const result = (await ApiService.apiMainGetCrmUserList(company_id!, value)).map((x) => ({
    //     value: x.crm_id,
    //     label: x.name,
    //     children: null,
    //   }));
    //   // setUsers(result);
    //   setFilterValues(result);
    //   selectUserRef.current?.focus();
    // };

    useEffect(() => {
      if (editing) {
        if (inputType === "input") {
          inputRef.current?.focus();
        } else if (inputType === "select_department") {
          selectDepartmentRef.current?.focus();
        } else if (inputType === "select_settings") {
          selectManagerSettingsRef.current?.focus();
        } else if (inputType === "search_user") {
          selectUserRef.current?.focus();
        }
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();

        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;
    const getDisplayValue = (dataIndex: any, record: any) => {
      const recordValue = record[dataIndex];
      // Если значение существует, возвращаем его
      if (recordValue) {
        return recordValue;
      }

      // Проверяем для каждого dataIndex и возвращаем значение из мапы
      if (dataIndex === "manager_settings") {
        return managerSettingsMap![record[dataIndex] as string]; // Возвращаем значение из мапы для manager_settings
      }
      if (dataIndex === "department") {
        return departmentsMap![record[dataIndex] as string]; // Возвращаем значение из мапы для department
      }

      return null; // Если ничего не найдено, возвращаем null
    };

    if (editing && inputType === "input") {
      childNode = (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      );
    } else if (editing && inputType === "select_settings") {
      childNode = (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Select onSelect={save} onBlur={save} ref={selectManagerSettingsRef}>
            {company?.company_manager_settings.map((x: any) => (
              <Select.Option value={x.id}>{x.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    } else if (editing && inputType === "select_department") {
      childNode = (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Select
            defaultValue={record[dataIndex]}
            onSelect={save}
            onBlur={save}
            ref={selectDepartmentRef}
          >
            {company?.company_departments.map((x: any) => (
              <Select.Option value={x.id} key={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    } else if (editing && inputType === "search_user") {
      childNode = (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Select
            showSearch
            optionLabelProp="label"
            filterOption
            // onSelect={(value) => dataIndex}
            // onSearch={debounce(handleUserSearch, 300)}
            options={users}
            placeholder="Найти юзера.."
            ref={selectUserRef}
          />
        </Form.Item>
      );
    } else if (editable) {
      childNode = (
        <div
          className="editable-cell-value-wrap"
          role="button"
          tabIndex={0}
          onClick={toggleEdit}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              toggleEdit();
            }
          }}
        >
          {getDisplayValue(dataIndex, record)}
        </div>
      );
    } else {
      childNode = <div>{children}</div>;
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const handleSubmit = async () => {
    try {
      console.log(dataSource);

      await ApiService.apiAccountsCompanyAddManagersCreate({
        managers: dataSource as ManagerRequest[],
        company_id: company_id!,
      });

      setCurrent(current + 1);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    inputType?: string;
    dataIndex: string;
  })[] = [
    {
      title: "ФИО",
      dataIndex: "full_name",
      width: "20%",
      editable: true,
      inputType: "input",
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: true,
      inputType: "input",
      width: "15%",
    },
    {
      title: "Настройки",
      dataIndex: "manager_settings",
      editable: true,
      inputType: "select_settings",
      width: "17.5%",
    },
    {
      title: "Подразделение",
      dataIndex: "department",
      editable: true,
      inputType: "select_department",
      width: "17.5%",
    },
    {
      title: "CRM ID",
      dataIndex: "crm_id",
      editable: true,
      inputType: "search_user",
      width: "25%",
    },
    {
      dataIndex: "operation",
      render: (_, record: any) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Удалить
            </a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData: DataType = {
      // key: count,
      full_name: `РОП ${count}`,
      email: "email@email.kz",
      crm_id: "123",
      department: "Выбрать..",
      manager_settings: "Выбрать..",
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        inputType: col.inputType,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <h4 className={s.stepTitle}>Шаг 6. Регистрация сотрудников</h4>
      <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
        Добавить Менеджера
      </Button>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
      />
      <Row>
        <Button htmlType="submit" style={{ marginRight: "1em" }} onClick={() => stepBack()}>
          Назад
        </Button>

        <Button type="primary" onClick={() => handleSubmit()}>
          Далее
        </Button>
      </Row>
    </>
  );
};
