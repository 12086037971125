import s from "./NeedResults.module.scss";

export const NeedResults = () => {
  const steps = [
    "Сможете оценить профессионализм наших менеджеров и увидите какие должны быть продажники",
    "Проведем диагностику вашей текущей ситуации, оценим ваше текущее положение и узкие горлышки",
    "Определим вашу идеальную картину",
    "Составим пошаговый план к вашей идеальной картине (покажем какие должны были регламенты, скрипты, адаптации, метрики, контроль и т.д.)",
    "Сделаем шикарное предложение к концу встречи",
  ];
  return (
    <div className={s.wrapper} id="results">
      <div className={s.main}>
        <div className={s.heading}>
          <p className={s.subtitle}>
            <span />
            нужен результат?
          </p>
          <h3 className={s.title}>
            Хотите также <span> улучшить</span> <br />
            свой отдел продаж?
          </h3>
          <p className={s.description}>
            Присоединяйтесь к числу уверенных в успехе компаний — уже более 70 остановили свой выбор
            на нас. Давайте вместе достигать новых вершин!
          </p>
        </div>
        {steps.map((step, index) => (
          <div className={s.step} key={index}>
            <p className={s.index}>0{index + 1}</p>
            <p className={s.label}>{step}</p>
          </div>
        ))}
      </div>
      <div className={s.reviews} />
    </div>
  );
};
