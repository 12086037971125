import s from "./LandingPage.module.scss";
import { AboutUs } from "./sections/AboutUs/AboutUs";
import { Cases } from "./sections/Cases/Cases";
import { Feedback } from "./sections/Feedback/Feedback";
import { Footer } from "./sections/Footer/Footer";
import { Header } from "./sections/Header/Header";
import { Hero } from "./sections/Hero/Hero";
import { NeedResults } from "./sections/NeedResults/NeedResults";

const LandingPage = () => {
  return (
    <div className={s.root}>
      <div className={s.container}>
        <div className={s.wrapper}>
          <Header />
          <Hero />
          <AboutUs />
          <Cases />
          <NeedResults />
          <Feedback />
          <Footer />
        </div>
      </div>
    </div>
  );
};
export { LandingPage };
