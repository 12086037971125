/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Collapse, Form, Input, Row, Space, Upload, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { ApiService } from "../../../../api";
import s from "../../css/Company/CompanyCreate.module.scss";
import { CreateCompanyProps } from "../../interfaces/Company";

// const text = `
//   A dog is a type of domesticated animal.
//   Known for its loyalty and faithfulness,
//   it can be found as a welcome guest in many households across the world.
// `;

export const CreateCompanyThirdStep = ({
  company_id,
  fetch,
  setFetch,
  company,
  setCurrent,
  current,
  stepBack,
}: CreateCompanyProps) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [managerSettings, setManagerSettings] = useState<any[]>([]);

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  // const items: CollapseProps["items"] = [
  //   {
  //     key: "1",
  //     label: "This is panel header 1",
  //     children: <p>{text}</p>,
  //     style: panelStyle,
  //   },
  //   {
  //     key: "2",
  //     label: "This is panel header 2",
  //     children: <p>{text}</p>,
  //     style: panelStyle,
  //   },
  //   {
  //     key: "3",
  //     label: "This is panel header 3",
  //     children: <p>{text}</p>,
  //     style: panelStyle,
  //   },
  // ];
  const handleDeleteSettings = async (id: any) => {
    try {
      await ApiService.apiAccountsManagerSettingDestroy(id);
      message.success("deleted");
      setFetch(fetch + 1);
    } catch (e) {
      message.error("Something went wrong");
    }
  };
  useEffect(() => {
    const settings =
      company?.company_manager_settings.map((x: any) => ({
        key: x.id,
        label: x.name,
        children: (
          <div>
            <div>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteSettings(x.id);
                }}
              >
                Удалить роль
              </a>
            </div>
          </div>
        ),
        style: panelStyle,
      })) ?? [];

    setManagerSettings(settings);
  }, [company]);

  const handleAddSettings = async (values: any) => {
    try {
      await ApiService.apiAccountsManagerSettingCreate({
        company_id: company_id!,
        name: values.name,
        daily_report_template: values.daily_report_template[0].originFileObj,
        weekly_report_template: values.weekly_report_template[0].originFileObj,
        monthly_report_template: values.monthly_report_template[0].originFileObj,
        sales_technique_template: values.sales_technique_template[0].originFileObj,
      });
      form.resetFields();
      setFetch(fetch + 1);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  const handleNextStep = async () => {
    if (!company_id || !company?.step_register) {
      message.error("Company information is incomplete");
      return;
    }

    try {
      await ApiService.apiAccountsCompanyPartialUpdate(company_id, {
        step_register: company.step_register + 1,
      });
      setFetch(fetch + 1);
      setCurrent(current + 1);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  return (
    <>
      <h4 className={s.stepTitle}>Шаг 3. Настройки менеджеров</h4>
      <Form form={form} layout="horizontal" onFinish={handleAddSettings}>
        <Row>
          <Col span={8}>
            <Form.Item noStyle>
              <Space>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, напишите название",
                    },
                  ]}
                >
                  <Input placeholder="Название роли менеджера.." />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Добавить
                  </Button>
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item>
              <Space>
                <span>Дневной скрипт: </span>
                <Form.Item
                  name="daily_report_template"
                  noStyle
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e && e.fileList}
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, загрузите дневной скрипт",
                    },
                  ]}
                >
                  <Upload
                    name="nda"
                    listType="text"
                    beforeUpload={() => {
                      return false;
                    }}
                    multiple={false}
                  >
                    <a>Загрузить</a>
                  </Upload>
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item>
              <Space>
                <span>Недельный скрипт: </span>
                <Form.Item
                  name="weekly_report_template"
                  noStyle
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e && e.fileList}
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, загрузите недельный скрипт",
                    },
                  ]}
                >
                  <Upload
                    name="nda"
                    listType="text"
                    beforeUpload={() => {
                      return false;
                    }}
                    multiple={false}
                  >
                    <a>Загрузить</a>
                  </Upload>
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item>
              <Space>
                <span>Месячный скрипт: </span>
                <Form.Item
                  name="monthly_report_template"
                  noStyle
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e && e.fileList}
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, загрузите месячный скрипт",
                    },
                  ]}
                >
                  <Upload
                    name="nda"
                    listType="text"
                    beforeUpload={() => {
                      return false;
                    }}
                    multiple={false}
                  >
                    <a>Загрузить</a>
                  </Upload>
                </Form.Item>
              </Space>
            </Form.Item>
            <Space>
              <span>Техники продаж: </span>
              <Form.Item
                name="sales_technique_template"
                noStyle
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, загрузите скрипт техники продаж",
                  },
                ]}
              >
                <Upload
                  name="nda"
                  listType="text"
                  beforeUpload={() => {
                    return false;
                  }}
                >
                  <a>Загрузить</a>
                </Upload>
              </Form.Item>
            </Space>
            <Form.Item style={{ marginTop: "1em" }}>
              <Button style={{ marginRight: "1em" }} onClick={() => stepBack()}>
                Назад
              </Button>

              <Button type="primary" onClick={() => handleNextStep()}>
                Далее
              </Button>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Collapse
              bordered={false}
              items={managerSettings}
              style={{ background: token.colorBgContainer }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
