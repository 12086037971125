/* eslint-disable jsx-a11y/anchor-is-valid */
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Form, Input, message, Row, Select, Space, theme } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService, DecompositionRequest } from "../../../../api";
import s from "../../css/Company/CompanyCreate.module.scss";
import { CreateCompanyProps } from "../../interfaces/Company";

export const CreateCompanySeventhStep = ({
  company_id,
  fetch,
  setFetch,
  company,
  setCurrent,
  current,
  stepBack,
}: CreateCompanyProps) => {
  const [form] = Form.useForm();
  const [pipelines, setPipelines] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const { token } = theme.useToken();
  const navigate = useNavigate();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const loadPipelines = async () => {
    const result = await ApiService.apiMainCrmPipelineRetrieve(company_id!);
    setPipelines(result);
    console.log(result);
  };
  const handleDelete = async (id: any) => {
    try {
      await ApiService.apiMainRnpDestroy(id);
      setFetch(fetch + 1);
    } catch (e) {
      message.error("Что то пошло не так");
    }

    setFetch(fetch + 1);
  };
  const loadRnps = async () => {
    const result = await ApiService.apiMainRnpGetDecompositionsByCompanyCreate(company_id!);

    const items =
      result.map((x: any) => ({
        key: x.id,
        label: x.department,
        children: (
          <div>
            <div>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(x.id);
                }}
              >
                Удалить настройки
              </a>
            </div>
          </div>
        ),
        style: panelStyle,
      })) ?? [];

    setItems(items);
  };
  useEffect(() => {
    (async () => {
      loadPipelines();
      loadRnps();
    })();
  }, [fetch]);

  const handleNextStep = async () => {
    if (company && company_id !== undefined) {
      try {
        // Убедитесь, что company.step_register определен
        const newStepRegister = (company.step_register ?? 0) + 1;

        await ApiService.apiAccountsCompanyPartialUpdate(company_id, {
          step_register: newStepRegister,
          is_active: true,
        });

        setFetch(fetch + 1);
        setCurrent(current + 1);
        message.success("RNP has been added successfully. Company registration has been finished.");
        navigate("/");
      } catch (e) {
        message.error("Something went wrong");
      }
    } else {
      message.error("Company or company ID is not defined.");
    }
  };

  const handlePipelineChange = async (value: any) => {
    const statuses = await ApiService.apiMainPipelineStatusList(company_id!, value);
    setStatuses(statuses);
  };

  const handleAddRnp = async (values: any) => {
    try {
      const data = {
        pipeline_id: values.pipeline,
        company_id,
        department: values.department,
        lead_field_id: values.lead_status,
        new_columns: values.statuses.map((x: any) => ({
          id: x.id,
          name: x.name,
        })),
        revenue_field_id: values.revenue_field_id,
        sales_field_id: values.sales_field_id,
      };

      await ApiService.apiMainRnpCreate(data as DecompositionRequest);

      setFetch(fetch + 1);
    } catch (e) {
      message.error("Что то пошло не так");
    }
  };

  return (
    <>
      <h4 className={s.stepTitle}>Шаг 6. Заполните настройки для таблицы РНП</h4>
      <Form form={form} layout="horizontal" onFinish={handleAddRnp}>
        <Row>
          <Col span={12}>
            <Form.Item
              name="department"
              label="Департамент"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, напишите название",
                },
              ]}
            >
              <Select>
                {company?.company_departments.map((x: any) => (
                  <Select.Option value={x.id}>{x.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="pipeline"
              label="Воронка"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите воронку",
                },
              ]}
            >
              <Select onChange={handlePipelineChange}>
                {pipelines.map((x: any) => (
                  <Select.Option value={x.id}>{x.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.List name="statuses">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item label={`Статус №${index + 1}`} required={false} key={field.key}>
                      <Space key={field.key} style={{ marginBottom: 8 }} align="baseline">
                        <Form.Item {...field} noStyle name={[field.name, "id"]}>
                          <Select placeholder="Выберите статус.." style={{ width: "100%" }}>
                            {statuses.map((x: any) => (
                              <Select.Option value={x.id}>{x.name}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item {...field} noStyle name={[field.name, "name"]}>
                          <Input placeholder="Название статуса" />
                        </Form.Item>
                      </Space>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          style={{ marginLeft: 10 }}
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      style={{ width: "60%" }}
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Добавить статус
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item
              name="lead_status"
              label="Статус для основной воронки"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите воронку",
                },
              ]}
            >
              <Select>
                {statuses.map((x: any) => (
                  <Select.Option value={x.id}>{x.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="sales_field_id"
              label="Статус для продаж"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите воронку",
                },
              ]}
            >
              <Select>
                {statuses.map((x: any) => (
                  <Select.Option value={x.id}>{x.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="revenue_field_id"
              label="Статус для выручки"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите воронку",
                },
              ]}
            >
              <Select>
                {statuses.map((x: any) => (
                  <Select.Option value={x.id}>{x.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item style={{ marginTop: "1em" }}>
              <Button type="primary" onClick={() => form.submit()}>
                Добавить
              </Button>
            </Form.Item>

            <Form.Item style={{ marginTop: "1em" }}>
              <Button style={{ marginRight: "1em" }} onClick={() => stepBack()}>
                Назад
              </Button>

              <Button type="primary" onClick={() => handleNextStep()}>
                Далее
              </Button>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Collapse
              bordered={false}
              items={items}
              style={{ background: token.colorBgContainer }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
