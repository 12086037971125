import { RouteObject } from "react-router-dom";
import { MainLayout } from "../../layouts/MainLayout";
import { TrainingBase } from "../../pages/Manager/TrainingBase";

export const ManagerRoutes: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <TrainingBase />,
      },
      {
        path: "/reports",
        element: <h1>Отчеты</h1>,
      },
      {
        path: "/rnp",
        element: <h1>РНП</h1>,
      },
      {
        path: "/analytics",
        element: <h1>Аналитика</h1>,
      },
    ],
  },
];
