/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import "./Table.css";

import { DownOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, GetRef, Input, InputRef, Progress, Row, Table } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ApiService, GetDepartmentsWithEmployeesDepartment } from "../../../api";
import useFilterStore from "../../../store/filterStore";
import { AddSectionModal } from "./AddSectionModal";

const { MonthPicker } = DatePicker;

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  date: string;
  [key: string]: any;
}

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item, key: string, value: any) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  date: string;
  [key: string]: any;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const Rnp = () => {
  const { filterValues, setFilterValues } = useFilterStore();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [addSectionModalVisible, setAddSectionModalVisible] = useState<boolean>(false);
  const [, setDepartmentsWithManagers] = useState<GetDepartmentsWithEmployeesDepartment[]>([]);
  // const [selectedManagers, setSelectedManagers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [fetch, setFetch] = useState<number>(0);
  const [progress, setProgress] = useState<any[]>([]);
  const [conversion, setConversion] = useState<any[]>([]);

  const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  const handleUpdate = async (record: any, dataIndex: any, value: any) => {
    try {
      const [status_id, field] = dataIndex.split("_");
      const manager_id = form.getFieldValue("manager_id");
      const decomposition_date = form.getFieldValue("decomposition_date");

      await ApiService.apiMainRnpUpdateRnpValueCreate({
        manager_id,
        field,
        status_id,
        date: record.key,
        value,
        decomposition_date: dayjs(decomposition_date).format("YYYY-MM-DD"),
      });

      setFetch(fetch + 1);
    } catch (error) {
      console.error("Error updating record:", error);
    }
  };
  const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    // handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
      if (editing) {
        inputRef.current?.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        console.log(values);
        toggleEdit();
        handleUpdate(record, dataIndex as string, values[dataIndex]);
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          // className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={toggleEdit}
          className="editable-cell-value-wrap"
          role="button"
          // aria-pressed={editing}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              toggleEdit();
            }
          }}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const addSection = (stage: string) => {
    const newData = [
      ...columns,
      {
        title: stage,
        children: [
          {
            title: "План",
            dataIndex: "plan",
            key: "age",
            width: 150,
            editable: true,
          },
          {
            title: "Факт",
            dataIndex: "fact",
            key: "age",
            width: 150,
          },
          {
            title: "Разница",
            dataIndex: "difference",
            key: "age",
            width: 150,
          },
        ],
      },
    ];
    setColumns(newData);
  };

  const loadDepartmentsWithManagers = async () => {
    try {
      const result = await ApiService.apiAccountsCompanyGetDepartmentsWithEmployeesList();
      setDepartmentsWithManagers(result);
    } catch (error) {
      console.error("Error loading departments with managers:", error);
    }
  };

  useEffect(() => {
    (async () => {
      await loadDepartmentsWithManagers();
    })();
  }, []);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const loadData = async () => {
    setIsLoading(true);
    let result: any;
    try {
      result = (await ApiService.apiMainRnpGetDecompositionsForRopRetrieve(
        filterValues?.date ?? dayjs().format("YYYY-MM-DD"),
        filterValues?.department,
        filterValues?.manager,
      )) as any;
    } catch (error) {
      console.log(error);
    }
    let cols = [];

    cols.push({
      title: "Общее",
      dataIndex: "date",
      key: "date",
      width: 150,
    });

    if (result) {
      const newCols = result?.columns.map((x: any) => ({
        title: x.name,
        children: [
          {
            title: "План",
            dataIndex: `${x.id}_plan`,
            key: `${x.id}_plan`,
            width: 150,
            editable: !!filterValues?.manager,
          },
          {
            title: "Факт",
            dataIndex: `${x.id}_fact`,
            key: `${x.id}_fact`,
            width: 150,
          },
          {
            title: "Разница",
            dataIndex: `${x.id}_difference`,
            key: `${x.id}_difference`,
            width: 150,
          },
        ],
      }));

      const defaultColumns = newCols.map((col: any) => {
        if (!col.children) {
          if (!col.editable) {
            return col;
          }
          return {
            ...col,
            onCell: (record: DataType) => ({
              record,
              editable: col.editable && !record.isTotal,
              dataIndex: col.dataIndex as keyof DataType,
              title: col.title,
              handleUpdate,
            }),
          };
        }
        const editableChildren = col.children.map((childCol: any) => {
          if (childCol.editable) {
            return {
              ...childCol,
              onCell: (record: DataType) => ({
                record,
                editable: childCol.editable && !record.isTotal,
                dataIndex: childCol.dataIndex as keyof DataType,
                title: childCol.title,
                handleUpdate,
              }),
            };
          }
          return childCol;
        });

        return {
          ...col,
          children: editableChildren,
        };
      });

      cols = [...cols, ...defaultColumns];

      setColumns(cols);

      const dates = [
        ...new Set(
          Object.values(result?.data).flatMap((item: any) =>
            item.by_day.map((day: any) => day.date),
          ),
        ),
      ];

      const dataSource = dates.map((date: any) => {
        const row: any = { key: date, date };

        result?.columns.forEach((col: any) => {
          const dayData = result?.data[col.id]?.by_day.find((day: any) => day.date === date) || {};
          row[`${col.id}_plan`] = dayData.plan || 0;
          row[`${col.id}_fact`] = dayData.fact || 0;
          row[`${col.id}_difference`] = dayData.difference || 0;
        });

        return row;
      });

      const totalRow: any = { key: "total", date: "Выполнение", isTotal: true };
      result?.columns.forEach((col: any) => {
        totalRow[`${col.id}_plan`] = result?.data[col.id].total.plan;
        totalRow[`${col.id}_fact`] = result?.data[col.id].total.fact;
        totalRow[`${col.id}_difference`] = result?.data[col.id].total.difference;
      });

      dataSource.unshift(totalRow);

      setDataSource(dataSource);

      const conversion = result?.conversion.map((x: any) => ({
        name: `${x.blocks?.from} -> ${x.blocks?.to}`,
        plan: x.plan,
        fact: x.fact,
      }));

      setConversion(conversion);

      const progress = result?.progress.map((x: any) => ({
        name: x.block?.name,
        fact: x.fact,
        plan: x.plan,
        progress: x.progress,
      }));

      setProgress(progress);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [filterValues, fetch]);

  // const handleDepartmentChange = (value: any) => {
  //   const department = departmentsWithManagers.find((x: any) => x.id === value);

  //   const managers =
  //     department?.department_employees.map((y: any) => ({
  //       value: y.id,
  //       title: y.full_name,
  //     })) ?? [];
  //   setSelectedManagers(managers);

  //   setFilterValues({
  //     ...filterValues,
  //     department: value,
  //     manager: null,
  //   });

  //   form.setFieldsValue({
  //     manager_id: null,
  //   });
  // };
  const [open, setOpen] = useState(false);

  const handleOpenChange = () => {
    setOpen(!open);
  };
  return (
    <>
      <Form layout="horizontal" form={form}>
        <Row gutter={16} style={{ marginBottom: "2em" }}>
          <Col>
            <Form.Item
              // label="Месяц"
              style={{ marginBottom: 0 }}
              name="decomposition_date"
              initialValue={dayjs()}
            >
              <MonthPicker
                // removeIcon
                suffixIcon={
                  open ? <UpOutlined className="rotate" /> : <DownOutlined className="rotate" />
                }
                onOpenChange={handleOpenChange}
                style={{ borderRadius: "47px", width: "160px" }}
                format="MMMM, YYYY"
                onChange={(value: any) =>
                  setFilterValues({
                    ...filterValues,
                    date: dayjs(value).format("MMMM, YYYY"),
                  })
                }
              />
            </Form.Item>
          </Col>
          {/* <Col>
            <Form.Item label="Подразделение" style={{ marginBottom: 0 }}>
              <Select onChange={handleDepartmentChange} style={{ width: 200 }}>
                {departmentsWithManagers.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          {/* <Col>
            <Form.Item label="Менеджер" style={{ marginBottom: 0 }} name="manager_id">
              <Select
                style={{ width: 200 }}
                onChange={(value: any) =>
                  setFilterValues({
                    ...filterValues,
                    manager: value,
                  })
                }
              >
                {selectedManagers.map((x) => (
                  <Select.Option key={x.value} value={x.value}>
                    {x.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>
      </Form>

      <Row gutter={[64, 0]}>
        <Col span={12}>
          <div className="excel-table">
            <Table
              showHeader={false}
              title={() => <b>Конверсия</b>}
              bordered
              dataSource={conversion}
              pagination={false}
            >
              <Table.Column dataIndex="name" />
              <Table.Column dataIndex="plan" />
              <Table.Column dataIndex="fact" />
            </Table>
          </div>
        </Col>
        <Col span={12}>
          <div className="excel-table">
            <Table bordered showHeader dataSource={progress} pagination={false}>
              <Table.Column dataIndex="name" />
              <Table.Column dataIndex="plan" title="План" />
              <Table.Column
                dataIndex="progress"
                title="%"
                render={(progress: any) => `${progress}%`}
              />
              <Table.Column dataIndex="fact" title="Выполнено" />
              <Table.Column
                dataIndex="progress"
                title="Прогресс"
                render={(progress: any) => (
                  <Progress percent={progress} showInfo={false} status="normal" />
                )}
              />
            </Table>
          </div>
        </Col>
      </Row>
      <div className="excel-table">
        <Table
          title={() => (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddSectionModalVisible(true)}
            >
              Добавить секцию
            </Button>
          )}
          loading={isLoading}
          bordered
          style={{ marginTop: "2.5em" }}
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          pagination={false}
        />
        <AddSectionModal
          visible={addSectionModalVisible}
          setVisible={setAddSectionModalVisible}
          addSection={addSection}
        />
      </div>
    </>
  );
};
export { Rnp };
