import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import {
  ApiService,
  CompanyRetrieve,
  GetDepartmentsWithEmployeesDepartment,
  GetDepartmentsWithEmployeesUser,
} from "../../../api";
import s from "./Employees.module.scss";
import { UpdateUserModal } from "./UpdateUserModal";

const Employees = () => {
  const [departmentsWithEmployees, setDepartmentsWithEmployees] = useState<
    GetDepartmentsWithEmployeesDepartment[]
  >([]);
  const [updateUserModalVisible, setUpdateUserModalVisible] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<GetDepartmentsWithEmployeesUser | undefined>(
    undefined,
  );
  const [company, setCompany] = useState<CompanyRetrieve>();
  const [fetch, setFetch] = useState<number>(0);

  const loadEmployees = async () => {
    const result = await ApiService.apiAccountsCompanyGetDepartmentsWithEmployeesList();
    setDepartmentsWithEmployees(result);
  };

  const loadCompany = async () => {
    const result = await ApiService.apiAccountsCompanyGetCompanyByUserRetrieve();
    setCompany(result);
  };

  useEffect(() => {
    (async () => {
      loadEmployees();
      loadCompany();
    })();
  }, [fetch]);

  return (
    <>
      <Row justify="space-between" wrap={false}>
        <Col>
          <div style={{ display: "flex", alignItems: " center" }}>
            <Form.Item noStyle name="name">
              <Input style={{ width: "40em" }} placeholder="Поиск.." />
            </Form.Item>
            <Button htmlType="submit" icon={<SearchOutlined />} style={{ marginLeft: "8px" }} />
          </div>
        </Col>
      </Row>
      <div className={s.departments}>
        {departmentsWithEmployees.map((department) => (
          <div className={s.department}>
            <p className={s.name}>{department.name}</p>
            <div className={s.employees}>
              {department.department_employees.map((employee) => (
                <div className={s.employee}>
                  <span className={s.fullName}>{employee.formatted_full_name}</span>
                  <EditOutlined
                    onClick={() => {
                      setSelectedUser(employee);
                      setUpdateUserModalVisible(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <UpdateUserModal
        fetch={fetch}
        setFetch={setFetch}
        visible={updateUserModalVisible}
        setVisible={setUpdateUserModalVisible}
        selectedUser={selectedUser}
        manager_settings={company?.company_manager_settings}
        departments={company?.company_departments}
      />
    </>
  );
};
export { Employees };
