import s from "./AboutUs.module.scss";

export const AboutUs = () => {
  const benefits = [
    {
      icon: "images/landingPage/clients.png",
      title: "8 из 10 клиентов",
      subtitle: "видят результат уже после первого месяца обучения",
    },
    {
      icon: "/images/landingPage/tools.png",
      title: "100+ инструментов",
      subtitle: "внедряем в процессе построения отдела продаж",
    },
    {
      icon: "/images/landingPage/departments.png",
      title: "Более 70+",
      subtitle: "Выстроенных отделов продаж",
    },
    {
      icon: "/images/landingPage/cases.png",
      title: "70+ кейсов",
      subtitle: "более 100 различных ниш",
    },
  ];
  return (
    <div className={s.wrapper} id="aboutUs">
      <div className={s.header}>
        <span className={s.dot} />
        <span className={s.title}>о нас</span>
      </div>
      <div className={s.main}>
        <div>
          <h3 className={s.title}>
            Узнайте о нашей команде и преимуществах, которые сделают ваш бизнес{" "}
            <span>более успешным</span>
          </h3>
          <div className={s.benefits}>
            {benefits.map((benefit, index) => (
              <div className={s.benefit} key={index}>
                <div className={s.benefitIcon}>
                  <img src={benefit.icon} alt="icon" />
                </div>
                <div>
                  <p className={s.benefitTitle}>{benefit.title}</p>
                  <p className={s.benefitSubtitle}>{benefit.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={s.team} />
      </div>
    </div>
  );
};
