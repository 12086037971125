import s from "./Hero.module.scss";

export const Hero = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.images}>
        <img src="images/landingPage/hero.png" alt="" />
      </div>
      <div className={s.information}>
        <div className={s.mainContent}>
          <h1 className={s.title}>
            Увеличьте свои продажи:
            <span>Эффективные инструменты и стратегии для бизнеса</span>
          </h1>
          <p className={s.description}>
            Продвигайте свой бизнес к новым высотам с нашими инновационными решениями и экспертными
            консультациями
          </p>
        </div>
        <div className={s.bottomContent}>
          <button className={s.details}>Узнать подробнее</button>
          <a href="tel:77777868987" target="_blank" className={s.phone} rel="noreferrer">
            +7 (777) 786 89 87
          </a>
        </div>
      </div>
    </div>
  );
};
