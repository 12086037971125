import { ConfigProvider } from "antd";
import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AppRoutes } from "./router/AppRoutes";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <ConfigProvider
    theme={{
      token: {
        fontFamily: "Inter",
      },
    }}
  >
    <React.StrictMode>
      <RouterProvider router={AppRoutes} />
    </React.StrictMode>
  </ConfigProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
