import { Form, Modal, Select } from "antd";

type AddSectionModalProps = {
  addSection: (stage: string) => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const AddSectionModal = ({ addSection, visible, setVisible }: AddSectionModalProps) => {
  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    addSection(values.stage);
    setVisible(false);
  };

  return (
    <Modal
      title="Добавить этап"
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
      open={visible}
    >
      <Form form={form} style={{ marginTop: "1em" }} onFinish={handleSubmit}>
        <Form.Item name="stage" label="Этап">
          <Select>
            <Select.Option value="Квалы">Квалы</Select.Option>
            <Select.Option value="Встреча назначена">Встреча назначена</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
