export const getAccessToken = () => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") || "{}")
    : null;
  return userInfo?.access ?? "";
};

export const getCurrentUserId = () => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") || "{}")
    : null;

  return userInfo?.id;
};

export const getCurrentUserGroups = () => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") || "{}")
    : null;

  return userInfo?.groups;
};

export const getCurrentUserName = () => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") || "{}")
    : null;

  return userInfo?.full_name;
};

export const getUserRole = () => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") || "{}")
    : null;

  return userInfo?.role;
};
