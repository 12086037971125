import s from "./VideoListSection.module.scss";
import clsx from "clsx";
import { Checkbox } from "antd";

const VideoListSection = () => {
  return (
    <div className={s.wrapper}>
      <h4 className={s.title}>Вам доступны тренировки</h4>
      <div className={s.items}>
        <div className={clsx(s.item, s.itemSuccess)}>
          <div>
            <Checkbox />
          </div>
          <span>Тема: Как правильно представиться клиенту</span>
        </div>
        <div className={clsx(s.item, s.itemDanger)}>
          <div>
            <Checkbox />
          </div>
          <span>Тема: Программирование</span>
        </div>
      </div>
    </div>
  );
};
export default VideoListSection;
