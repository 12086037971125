import { Form, Input, message, Modal } from "antd";
import { useEffect } from "react";
import { ApiService } from "../../../../../api";
import { CompanyDetailsModalProps } from "../../../interfaces/Company";

export const ChangeNameModal = ({
  company_id,
  setVisible,
  visible,
  fetch,
  setFetch,
  company,
}: CompanyDetailsModalProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: company?.name,
      owner_name: company?.owner_name,
      owner_email: company?.owner_email,
      owner_phone: company?.owner_phone,
    });
  }, [company]);

  const handleSubmit = async (values: any) => {
    try {
      await ApiService.apiAccountsCompanyPartialUpdate(company_id!, {
        name: values.name,
      });
      // await ApiService.apiAccountsUserUpdate(company_id!, {
      //   full_name: values.owner_name,
      //   email: values.owner_email,
      //   phone: values.owner_phone,
      //   company: values.name,
      //   country: '',
      //   dob: values.owner_dob,
      // });
      message.success("Имя компании успешно сохранено");
      setVisible(false);
      setFetch(fetch + 1);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  return (
    <Modal
      title="Изменить CRM"
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
    >
      <Form form={form} style={{ marginTop: "1em" }} onFinish={handleSubmit}>
        <Form.Item name="name" label="Название компании">
          <Input />
        </Form.Item>
        <Form.Item name="owner_name" label="Владелец">
          <Input />
        </Form.Item>
        <Form.Item name="owner_email" label="e-mail владельца">
          <Input />
        </Form.Item>
        <Form.Item name="owner_phone" label="Номер телефона">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
