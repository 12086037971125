/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { EditOutlined } from "@ant-design/icons";
import { Breadcrumb, Divider, Row, Space, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService, CompanyRetrieve, ManagerSettingsCompanyList } from "../../../../api";
import { ChangeCRMModal } from "../../components/Company/modals/ChangeCRMModal";
import { ChangeManagerSettingsModal } from "../../components/Company/modals/ChangeManagerSettingsModal";
import { ChangeNameModal } from "../../components/Company/modals/ChangeNameModal";
import Status from "../../components/ui/Status/Status";
import s from "../../css/Company/CompanyDetails.module.scss";

export const Details = () => {
  const { id } = useParams();
  const [company, setCompany] = useState<CompanyRetrieve>();
  const navigate = useNavigate();
  const [changeCrmModalVisible, setChangeCrmModalVisible] = useState<boolean>(false);
  const [changeNameModalVisible, setChangeNameModalVisible] = useState<boolean>(false);
  const [changeManagerSettingsVisible, setChangeManagerSettingsModalVisible] =
    useState<boolean>(false);
  const [selectedManagerSettingsId, setSelectedManagerSettingsId] = useState<any>();
  const [fetch, setFetch] = useState<number>(0);
  const loadCompany = async (id: any) => {
    const result = await ApiService.apiAccountsCompanyRetrieve(id);

    if (result.is_active === false) {
      navigate(`/company/create/${result.id}`);
    }

    setCompany(result);
  };
  useEffect(() => {
    (async () => {
      loadCompany(id);
    })();
  }, [fetch]);

  // const downloadTemplate = async (id: string, templateType: string) => {
  //   try {
  //     const result = await ApiService.apiAccountsManagerSettingDownloadTemplateRetrieve(
  //       id,
  //       templateType,
  //     );

  //     if (result instanceof Blob || result instanceof ArrayBuffer) {
  //       fileDownload(result, `${company?.name}_${templateType}.xlsx`);
  //     } else {
  //       console.log("Invalid file format received");
  //     }
  //   } catch (e) {
  //     console.log("Failed to download the file");
  //   }
  // };

  // const renderTabContent = (id: any) => {
  //   const items = [
  //     {
  //       name: "Дневной скрипт",
  //       id,
  //       templateType: "daily_report",
  //     },
  //     {
  //       name: "Недельный скрипт",
  //       id,
  //       templateType: "weekly_report",
  //     },
  //     {
  //       name: "Месячный скрипт",
  //       id,
  //       templateType: "monthly_report",
  //     },
  //     {
  //       name: "Техники продаж скрипт",
  //       id,
  //       templateType: "sales_technique",
  //     },
  //   ];

  //   return (
  //     <Table
  //       bordered
  //       showHeader={false}
  //       dataSource={items}
  //       pagination={{ position: [] }}
  //       style={{ width: "30%" }}
  //     >
  //       <Table.Column dataIndex="name" />
  //       <Table.Column
  //         render={(record: any) => (
  //           <a
  //             href="#"
  //             onClick={(e) => {
  //               e.preventDefault();
  //               downloadTemplate(record.id, record.templateType);
  //             }}
  //           >
  //             Скачать
  //           </a>
  //         )}
  //       />
  //     </Table>
  //   );
  // };

  return (
    <>
      <Breadcrumb
        className={s.breadcrumb}
        items={[
          {
            title: <a href="/">Все компании</a>,
          },
          {
            title: <a href="#">{company?.name}</a>,
          },
        ]}
      />
      <Row justify="space-between">
        {/* <h3 className={s.name}>
          {company?.name} <EditOutlined onClick={() => setChangeNameModalVisible(true)} />
        </h3> */}
        <h3 className={s.name}>
          {company?.name} <EditOutlined onClick={() => navigate(`/company/create/${id}`)} />
        </h3>
        <Space>
          <div className={s.subscription}>
            Подписка: c {dayjs(company?.package_permission?.date_start).format("DD.MM.yyyy")} по{" "}
            {dayjs(company?.package_permission?.date_end).format("DD.MM.yyyy")}
          </div>
          <Status state={company?.is_active} />
        </Space>
      </Row>

      <div className={s.label}>
        Владелец: <span>{company?.owner_name}</span>
      </div>
      <div className={s.label}>
        Количество сотрудников:{" "}
        <span>
          <a href="" onClick={() => navigate(`/company/${id}/employees`)}>
            {company?.staff_count}
          </a>
        </span>
      </div>
      <div className={s.label}>
        Количество подразделений:{" "}
        <span>
          <a href="" onClick={() => navigate(`/company/${id}/departments`)}>
            {company?.company_departments.length}
          </a>
        </span>
      </div>
      <div className={s.label}>
        e-mail владельца: <span>{company?.owner_email}</span>
      </div>
      <div className={s.label}>
        Номер телефона: <span>{company?.owner_phone}</span>
      </div>

      <Divider />

      <div className={s.label}>
        Тип CRM: <span>{company?.crm_type}</span>
      </div>
      <div className={s.label}>
        Вебхук/Токен:{" "}
        <span>
          <EditOutlined onClick={() => setChangeCrmModalVisible(true)} />
        </span>
      </div>

      <Divider />

      <Tabs type="card">
        {company?.company_manager_settings.map((x: ManagerSettingsCompanyList) => (
          <TabPane
            key={x.id}
            tab={
              <div>
                {x.name}{" "}
                <EditOutlined
                  onClick={() => {
                    setSelectedManagerSettingsId(x.id);
                    setChangeManagerSettingsModalVisible(true);
                  }}
                />
              </div>
            }
            // children={renderTabContent(x.id)}
          />
        ))}
      </Tabs>
      <ChangeCRMModal
        company_id={id}
        visible={changeCrmModalVisible}
        setVisible={setChangeCrmModalVisible}
        fetch={fetch}
        setFetch={setFetch}
        company={company}
      />
      <ChangeNameModal
        company_id={id}
        visible={changeNameModalVisible}
        setVisible={setChangeNameModalVisible}
        fetch={fetch}
        setFetch={setFetch}
        company={company}
      />
      <ChangeManagerSettingsModal
        company_id={id}
        visible={changeManagerSettingsVisible}
        setVisible={setChangeManagerSettingsModalVisible}
        fetch={fetch}
        setFetch={setFetch}
        company={company}
        manager_settings_id={selectedManagerSettingsId}
      />
    </>
  );
};
