/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { AppRoutesName } from "../../../../router/AppRoutesName";
import s from "./Header.module.scss";

type Props = {
  hideMenuLinks?: boolean;
};
export const Header: FC<Props> = ({ hideMenuLinks }) => {
  const menuLinks = [
    {
      label: "Главная",
      link: "#",
    },
    {
      label: "О нас",
      link: "#aboutUs",
    },
    {
      label: "Кейсы",
      link: "#cases",
    },
    {
      label: "Наши продукты",
      link: "#results",
    },
    {
      label: "Статьи",
      link: "#",
    },
    {
      label: "Статьи",
      link: "#",
    },
  ];
  const location = useLocation();
  const getLinkInfo = () => {
    if (location.pathname === "/login") {
      return { label: "Вернуться на сайт", link: "/landing" };
    }
    if (location.pathname === "/landing") {
      return { label: "Вернуться на платформу", link: "/login" };
    }
    return null;
  };
  const linkInfo = getLinkInfo();
  return (
    <div className={s.wrapper}>
      <a href={AppRoutesName.landingPage}>
        <img width={91} src="logo_satoo.svg" alt="logo" />
      </a>
      {!hideMenuLinks ? (
        <div className={s.menu}>
          {menuLinks.map((item, index) => (
            <div className={s.menuItem} key={index}>
              <a className={s.menuItemLink} href={item.link}>
                {item.label}
              </a>
            </div>
          ))}
          <div className={s.menuItem}>
            <a href="#" className={s.menuItemLink}>
              Хочешь к нам в команду?
            </a>
          </div>
        </div>
      ) : null}
      {linkInfo && (
        <a href={linkInfo.link} className={s.goToPlatform}>
          {linkInfo.label}
        </a>
      )}
    </div>
  );
};
