/* eslint-disable jsx-a11y/anchor-is-valid */
import s from "./Cases.module.scss";

export const Cases = () => {
  const casesItems = [
    {
      img: "images/landingPage/cases1.png",
      title: "Услуги",
      subtitle: "Реализовано <span><b>26 кейсов</b></span>",
      link: "#",
    },
    {
      img: "images/landingPage/cases2.png",
      title: "Производство",
      subtitle: "Реализовано <span><b>4 кейса</b></span>",
      link: "#",
    },
    {
      img: "images/landingPage/cases3.png",
      title: "Товарооборот",
      subtitle: "Реализовано <span><b>10 кейсов</b></span>",
      link: "#",
    },
  ];

  return (
    <div className={s.wrapper} id="cases">
      <div className={s.header}>
        <span className={s.dot} />
        <span className={s.title}>кейсы</span>
      </div>
      <h3 className={s.mainTitle}>
        Наша компания реализовала <span>70+ кейсов</span>
      </h3>
      <div className={s.cases}>
        {casesItems.map((caseItem, index) => (
          <div className={s.casesItem} key={index}>
            <a href="#" className={s.casesItemLink}>
              <div
                className={s.casesItemImg}
                style={{ backgroundImage: `url(${caseItem.img})`, marginTop: "-62px" }}
              />
              <h3 className={s.casesItemTitle}>{caseItem.title}</h3>
              <p
                className={s.casesItemSubtitle}
                dangerouslySetInnerHTML={{ __html: caseItem.subtitle }}
              />
              <img className={s.casesItemIcon} src="images/landingPage/arrow.svg" alt="" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
