import en from "./Resources.en.json";
import ru from "./Resources.ru.json";
import kk from "./Resources.kk.json";
import { Resource } from "i18next";

export const Resources: Resource = {
  en: { translation: { ...en } },
  ru: {
    translation: { ...en, ...ru },
  },
  kk: {
    translation: { ...en, ...kk },
  },
};
