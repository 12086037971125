const VideoSection = () => {
  return (
    <div>
      <iframe
        width="100%"
        height="384"
        src="https://www.youtube.com/embed/dqX0bL8kPuo?si=rKiD35Ely943WuPw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </div>
  );
};
export default VideoSection;
