/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, message, Modal, Upload } from "antd";
import { useEffect } from "react";
import { ApiService } from "../../../../../api";
import { CompanyDetailsModalProps } from "../../../interfaces/Company";

export const ChangeManagerSettingsModal = ({
  setVisible,
  visible,
  fetch,
  setFetch,
  company,
  manager_settings_id,
}: CompanyDetailsModalProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: company?.name,
    });
  }, [company]);

  const handleSubmit = async (values: any) => {
    try {
      await ApiService.apiAccountsManagerSettingUpdate(manager_settings_id!, {
        daily_report_template: values.daily_report_template[0].originFileObj,
        weekly_report_template: values.weekly_report_template[0].originFileObj,
        monthly_report_template: values.monthly_report_template[0].originFileObj,
        sales_technique_template: values.sales_technique_template[0].originFileObj,
      });
      message.success("Имя компании успешно сохранено");
      setVisible(false);
      setFetch(fetch + 1);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  const beforeUpload = (file: any) => {
    const isExcel =
      file.type === "application/vnd.ms-excel" ||
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      message.error("Вы можете загружать только Excel файлы!");
    }
    return isExcel || Upload.LIST_IGNORE; // prevent upload if not Excel
  };

  return (
    <Modal
      title="Обновить скрипты"
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
    >
      <Form form={form} style={{ marginTop: "1em" }} onFinish={handleSubmit}>
        <Form.Item
          name="daily_report_template"
          label="Дневной скрипт"
          valuePropName="fileList"
          getValueFromEvent={(e) => e && e.fileList}
          rules={[
            {
              required: true,
              message: "Пожалуйста, загрузите дневной скрипт",
            },
          ]}
        >
          <Upload name="nda" listType="text" beforeUpload={beforeUpload} multiple={false}>
            <a href="#">Загрузить</a>
          </Upload>
        </Form.Item>
        <Form.Item
          name="weekly_report_template"
          label="Недельный скрипт"
          valuePropName="fileList"
          getValueFromEvent={(e) => e && e.fileList}
          rules={[
            {
              required: true,
              message: "Пожалуйста, загрузите недельный скрипт",
            },
          ]}
        >
          <Upload name="nda" listType="text" beforeUpload={beforeUpload} multiple={false}>
            <a>Загрузить</a>
          </Upload>
        </Form.Item>
        <Form.Item
          name="monthly_report_template"
          label="Месячный скрипт"
          valuePropName="fileList"
          getValueFromEvent={(e) => e && e.fileList}
          rules={[
            {
              required: true,
              message: "Пожалуйста, загрузите месячный скрипт",
            },
          ]}
        >
          <Upload name="nda" listType="text" beforeUpload={beforeUpload} multiple={false}>
            <a>Загрузить</a>
          </Upload>
        </Form.Item>
        <Form.Item
          name="sales_technique_template"
          label="Скрипт техники продаж"
          valuePropName="fileList"
          getValueFromEvent={(e) => e && e.fileList}
          rules={[
            {
              required: true,
              message: "Пожалуйста, загрузите скрипт техники продаж",
            },
          ]}
        >
          <Upload name="nda" listType="text" beforeUpload={beforeUpload} multiple={false}>
            <a>Загрузить</a>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
