import { useEffect, useState } from "react";
import { ApiService, BlockList } from "../../../api";
import s from "./TrainingBase.module.scss";
import { ControlPanel } from "./sections/ControlPanel";
import { VideoList } from "./sections/VideoList";

const TrainingBase = () => {
  const [trainingBaseData, setTrainingBaseData] = useState<BlockList[]>([]);
  const fetchTrainingBase = async () => {
    try {
      const trainingBaseData = await ApiService.apiMainBlockList();
      setTrainingBaseData(trainingBaseData);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchTrainingBase();
  }, []);

  return (
    <div className={s.root}>
      <ControlPanel trainingBaseData={trainingBaseData} fetchTrainingBase={fetchTrainingBase} />
      <div className={s.videoList}>
        {trainingBaseData.map((block) => (
          <VideoList videoBlock={block} key={block.id} />
        ))}
      </div>
    </div>
  );
};
export { TrainingBase };
